<template>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->

  <div class="bgi">
    <div class="content">
    <div class="w100 rowsc rowsm fs-40 fw-b colfff mb-30 pt-16" style="position: relative;">
      <div class="">临沂大学劳动教育数据展示</div>
      <div class="fs-20 colfff" style="position: absolute;right: 30px;">{{ now | getTime }}</div>
    </div>

    <div class="w100 rowsc rowsm mb-20 colfff fs-24">
      <div class="rowscl rowsm">
        <div class="">登录人数</div>
        <div class="fw-b">6890</div>
      </div>

      <div class="rowscl rowsm ml-60 mr-60">
        <div class="">平均参与率</div>
        <div class="fw-b">63%</div>
      </div>

      <div class="rowscl rowsm">
        <div class="">参与人数</div>
        <div class="fw-b">4340 人</div>
      </div>
    </div>


    <!-- <div class="colfff fs-20" style="position: absolute;right: -20px;top: -20px">更多</div> -->

    <div class="w100 rows rowsm mb-30">
      <div class="w-466 h-300" id="bar"></div>
      <div class="w-466 h-300" id="line"></div>
      <div class="w-466 h-300" id="pie"></div>
    </div>

    <div class="w100 h-400" id="barAll"></div>
  </div>
  </div>
</template>

<script>

// import HelloWorld from './components/HelloWorld.vue'.
import * as echarts from "echarts";
export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data(){
    return {
      now:0,
      timer:''
    }
  },
  created(){
    const now = new Date()
    this.now = now.getTime()
    this.timer = setInterval(() => {
      const now2 = new Date()
      this.now = now2.getTime()
    },1000)
  },
  mounted(){
    this.getBarData()
    this.getLineData()
    this.getPieData()
    this.getBarAllData()
  },
  methods:{
    getBarData(){
      const bar = echarts.init(document.getElementById('bar'))
      let option = null
      
      option = {
          title: {
    text: '劳育人均得分柱状图 ',
    // subtext: 'Fake Data',
    left: 'center',
    top:'0',
textStyle:{
  color:'#fff'
}
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    top:'40',
     textStyle:{
      color:'#fff'
     }
  },
  toolbox:{
    feature: {
      // saveAsImage: {}
      myTool1:{
        show: true,
        title: '更多',
        icon: 'image://http://ldjy.zxcvii.top/file/zb4.png',
        onclick: function (){
            // alert('myToolHandler1')
        },
        iconStyle:{
              color:'#fff',     
                },
        emphasis:{
            iconStyle:{
              color:'#fff',                
            },               
        },
      }
    }
  },
  color:['#456bc9', '#7d9dee', '#ca7338', '#f3bc97', '#9420ae', '#d777ed', '#18bc23', '#72f37b'],
  grid: {
    left: '3%',
    right: '40',
    bottom: '3%',
    top:'100',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['马克思主义学院','商学院','物流学院','法学院'],
      name:'学院',
      axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
           }

    }
  ],
    textStyle:{
    color:'#fff'
  },
  yAxis: [
    {
      type: 'value',
      name:'平均'
    }
  ],
  series: [
    {
      name: '2021级',
      type: 'bar',
      stack: '2021',
      emphasis: {
        focus: 'series'
      },
      data: [44, 46, 43,40]
    },
    {
      name: '2021级基础劳动',
      type: 'bar',
      stack: '2021',
      emphasis: {
        focus: 'series'
      },
      data: [48, 48 , 48 ,48 ]
    },
    {
      name: '2022级',
      type: 'bar',
      stack: '2022',
      emphasis: {
        focus: 'series'
      },
      data: [33, 34, 32,18]
    },
    {
      name: '2022级基础劳动',
      type: 'bar',
      stack: '2022',
      emphasis: {
        focus: 'series'
      },
      data: [48, 48, 48,38]
    },
    {
      name: '2023级',
      type: 'bar',
      stack: '2023',
      emphasis: {
        focus: 'series'
      },
      data: [28 , 31, 32 ,18 ]
    },
    {
      name: '2023级基础劳动',
      type: 'bar',
      stack: '2023',
      emphasis: {
        focus: 'series'
      },
      data: [45,43 , 40, 27]
    },
    {
      name: '2024级',
      type: 'bar',
      stack: '2024',
      emphasis: {
        focus: 'series'
      },
      data: [20, 36, 36,18]
    },
    {
      name: '2024级基础劳动',
      type: 'bar',
      stack: '2024',
      emphasis: {
        focus: 'series'
      },
      data: [40, 32, 36,16]
    }
  ]
};

bar.setOption(option)
    },
    getLineData(){
      const line = echarts.init(document.getElementById('line'))
      let option = null
      option = {
  title: {
    text: '劳动教育平台使用变化折线图',
                left: 'center',
            top:'30',
            textStyle:{
  color:'#fff'
}
  },
    textStyle:{
    color:'#fff'
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: ['参与人数', '登录人数'],
    textStyle:{
      color:'#fff'
     }
  },
  grid: {
    left: '3%',
    right: '40',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    name:'月份',
    boundaryGap: false,
    data: ['1', '2', '4', '6', '8', '10','12']
  },
  yAxis: {
    type: 'value',
    name:'人数'

  },
  series: [
    {
      name: '参与人数',
      type: 'line',
      // stack: 'Total',
      data: [1200, 1320, 1010, 1340, 900, 2300, 2100]
    },
    {
      name: '登录人数',
      type: 'line',
      // stack: 'Total',
      data: [2200, 1820, 1910, 2340, 2900, 3300, 3100]
    },
  ]
};
line.setOption(option)

    },
    getPieData(){
      const pie = echarts.init(document.getElementById('pie'))
      let option = null
      option = {
  title: {
    text: '基础劳动类别占比饼形图',
    // subtext: 'Fake Data',
    left: 'center',
    textStyle:{
  color:'#fff'
}
  },
    textStyle:{
    color:'#fff'
  },
  tooltip: {
    trigger: 'item'
  },
  grid: {
    left: '3%',
    right: '0',
    bottom: '0',
    top: '0',
    containLabel: true
  },
  legend: {
    orient: "horizontal",
    bottom: 'bottom',
    show:false,
    textStyle:{
      color:'#fff'
     }
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 15, name: '宿舍、教室、实验室等劳动 15%' },
        { value: 8, name: '科技帮扶、文化宣传的呢过社会实践活动 8%' },
        { value: 26, name: '劳动周 26%' },
        { value: 12, name: '校内公共区域劳动 12%' },
        { value: 5, name: '学校大型志愿服务 5%' },
        { value: 4, name: '劳模报告心得 4%' },
        { value: 5, name: '大学生创新创业训练计划 5%' },
        { value: 3, name: '互联网+、挑战杯等比赛 3%' },
        { value: 8, name: '劳动演讲比赛、知识竞赛等 8%' },
        { value: 10, name: '劳动之星、文明宿舍、劳动模范 10%' },
        { value: 4, name: '志愿服务、社区服务 4%' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      label:{
      color:'#fff'

      }
    }
  ]
};



      pie.setOption(option)

    },
    getBarAllData(){
      const barAll = echarts.init(document.getElementById('barAll'))
      let option = null

      option = {
          title: {
    text: '学院劳动实践类别完成率柱状图 ',
    // subtext: 'Fake Data',
    left: 'center',
    top:'0',
    textStyle:{
  color:'#fff'
}
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    top:'40',
    textStyle:{
      color:'#fff'
     }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['马克思主义学院', '商学院','物流学院','法学院','教育学院','体育与健康学','音乐学院','美术学院','文学院','外国语学院'],
      name:'学院'
    }
  ],
  yAxis: [
    {
      type: 'value',
      name:'百分比'
    }
  ],
  textStyle:{
    color:'#fff'
  },
  series: [
    {
      name: '基础劳动百分比',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [78, 80, 65, 89, 76, 86, 84, 62,78,78 ]
    },
    {
      name: '社会公益活动百分比',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [65, 59, 50, 53, 39, 76, 66, 32,65,64]
    },
    {
      name: '劳动总结报告百分比',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [45, 42, 31, 24, 18, 35, 45, 41,59,58]
    },
    {
      name: '劳动成果百分比',
      type: 'bar',
      stack: 'Ad',
      emphasis: {
        focus: 'series'
      },
      data: [54, 44, 47, 49, 37, 29, 34, 41,54,49]
    },
    {
      name: '总完成率百分比',
      type: 'bar',
      emphasis: {
        focus: 'series'
      },
      data: [40, 35, 26, 22, 12, 18, 26, 32,46,34]
    }
  ]
};


      barAll.setOption(option)

    }
  },
  filters:{
    getTime(time){
      const now = new Date(time)
      let chineseNum = ['日','一','二','三','四','五','六']
      return `${now.getFullYear()}年${(now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1)}月${(now.getDate()) < 10 ? '0' + (now.getDate()) : (now.getDate())}日 ${(now.getHours()) < 10 ? '0' + (now.getHours()) : (now.getHours())}:${(now.getMinutes()) < 10 ? '0' + (now.getMinutes()) : (now.getMinutes())}:${(now.getSeconds()) < 10 ? '0' + (now.getSeconds()) : (now.getSeconds())} 星期${chineseNum[now.getDay()]}`
    }
  }
}
</script>

<style lang="scss">
	@import "/src/static/style_scss.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.content {
  margin: 0 auto;
  width: 1400px;
  // background: #000;
  background-image: url('./static/BG.png');
  //  background-position: center center;
    background-size: cover;
    background-position: center; /* 将图片居中显示 */
  min-height: 100vh; /* 确保至少为视口高度 */

}
</style>
